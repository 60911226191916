<template>
  <div id="cps">
    <div class="content_box">
      <div class="inner_max">
        <div class="page-header">{{ $t('menu.depositFund') }} - (Special Page)</div>
        <div class="form_deposit">
          <div v-if="isMultiplePaymentTypes" class="info_box_choose">
            <p>
              {{ $t('deposit.method.choose', { method: paymentDetails.description_title }) }}
            </p>
            <el-button
              v-for="(item, index) in paymentTypes"
              :class="elButtonClassName(index == activeIndex)"
              @click="handleSelectIndex(index)"
              :data-testid="item.index"
              :key="item.index"
            >
              <div class="button_icon_wrapper">
                <span>{{ langTranslate('deposit.cpsInfo.', item.merchant_variable.label) }}</span>
                <img
                  v-if="cryptoPayment.includes(item.payment_method)"
                  :src="paymentLogo(item.payment_method, item.actual_currency_code)"
                  alt="logo"
                />
              </div>
            </el-button>
          </div>

          <!-- CPS Component -->
          <InternationalBankCps v-if="isIBT" />
          <CPS v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CPS from '@/components/deposit/Cps/IndexCps.vue';
import InternationalBankCps from '@/components/deposit/Cps/InternationalBankCps.vue';
import multiplePaymentMixin from '@/mixins/page/deposit/multiplePayment';
import cpsMixin from '@/mixins/cpsDeposit';
import { internationalBankData } from '@/constants/payment/depositSetting.json';

export default {
  name: 'cps',
  components: { CPS, InternationalBankCps },
  mixins: [multiplePaymentMixin, cpsMixin],
  data() {
    return {
      activeIndex: 0
    };
  },
  computed: {
    paymentDetails() {
      return this.$store.state.cps.payment;
    },
    currentSelectedPayment() {
      return this.$store.state.cps.currentPayment;
    },
    paymentTypes() {
      let paymentTypes = this.paymentDetails.child;

      return paymentTypes;
    },
    isMultiplePaymentTypes() {
      return this.paymentTypes.length > 1;
    },
    paymentMethod() {
      return this.paymentDetails.payment_method;
    },
    isIBT() {
      return internationalBankData.includes(this.paymentMethod);
    }
  },
  methods: {
    handleSelectIndex(index) {
      this.activeIndex = index;

      if (this.paymentTypes.length > 0) {
        let payment = this.paymentTypes[index];
        this.$store.dispatch('cps/actionSetCurrentPayment', payment);
      }
    }
  },
  mounted() {
    // get selected payment index
    if (this.paymentTypes.length > 0) {
      const paymentIndex = this.paymentTypes.findIndex(i => {
        return i.merchant_variable.label == this.currentSelectedPayment.merchant_variable?.label;
      });
      this.activeIndex = paymentIndex == -1 ? 0 : paymentIndex;
      this.handleSelectIndex(this.activeIndex);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
@import '@/assets/css/components/deposit/multipleButton.scss';
</style>
